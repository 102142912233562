import React, { useState, useEffect, useRef, useCallback } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import WidgetTool from "../../../components/Toolbars/WidgetToolbar";
import TextToolbar from "../../../components/Toolbars/TextToolbar";
import FileDrop from "../../../components/Toolbars/FileDrop";
import {
  setEdit,
  setSideBar,
  setStopDrag,
  updateTracker,
} from "../../../store/slices/bodyStyling";
import { updateWigetsStyles } from "../../../store/slices/bodyStyling";
import ButtonToolbar from "../../../components/Toolbars/ButtonToolbar";
import Draggable from "react-draggable";
import useResizeObserver from "use-resize-observer";
import { getGradient } from "../../../utills/getGradient";
import { Rnd } from "react-rnd";
import { Resizable } from "react-resizable";
import { Trash2 } from "lucide-react";

const CustomWidget = ({
  height,
  width,
  widgetId,
  itemStyles,
  deleteWidget,
  x,
}) => {
  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const draggable = useSelector((state) => state.bodyStyling.isEdit);

  const { mainColor } = universalStyles;

  const [default_widget_colors, setDefaultWidgetColors] = useState({});

  const [imagesArray, setImagesArr] = useState([]);

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (mainRef.current) {
      setContainerSize({
        width: mainRef.current.offsetWidth,
        height: mainRef.current.offsetHeight,
      });
    }
  }, []);

  const pixelToPercent = (pixel, dimension) => {
    // console.log('SIZE>',containerSize[dimension]);
    // console.log("PERC>", (pixel / (containerSize[dimension] * 0.78)) * 100);
    if (dimension === "width") {
      // return (pixel / (containerSize[dimension] * 0.78)) * 100;
      return ((pixel + 50) / containerSize[dimension]) * 100;
      // return (pixel / containerSize[dimension]) * 100;
    }
    return (pixel / containerSize[dimension]) * 100;
  };

  useEffect(() => {
    if (universalStyles.default_widget_colors) {
      try {
        const default_widget_colors = JSON.parse(
          universalStyles.default_widget_colors
        );
        setDefaultWidgetColors(default_widget_colors);
      } catch (error) {
        const default_widget_colors = universalStyles.default_widget_colors;
        setDefaultWidgetColors(default_widget_colors);
      }
    }
  }, [universalStyles]);

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
    if (universalStyles.font) {
      font.isDefaultFont == 1 &&
        setFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
      headingfont.isDefaultFont == 1 &&
        setHeadingFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
      parafont.isDefaultFont == 1 &&
        setParaFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
      buttonFont.isDefaultFont == 1 &&
        setButtonFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setImage(itemStyles.background_image);
      // setColor(itemStyles.background_color);
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      } else {
        if (itemStyles.background_image) {
          setImage(itemStyles.background_image);
        }
      }

      // setBtnBg(itemStyles.button_background_color);
      if (itemStyles.button_background_color) {
        try {
          const bgstyle = JSON.parse(itemStyles.button_background_color);
          setBtnBg(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.button_background_color;
          setBtnBg(bgstyle);
        }
      }

      if (itemStyles.images) {
        try {
          const items = JSON.parse(itemStyles.images);
          setImagesArr(items);
        } catch (error) {
          const items = itemStyles.images;
          setImagesArr(items);
        }
      }

      setBtnTxt(itemStyles.button_text);
      setTxtColor(itemStyles.button_text_color);
      setBtnLink(itemStyles.button_link);
      setBtnAlignment(itemStyles.button_alignmnet);

      setTextAlignment(itemStyles.text_alignmnet);
      setText(itemStyles.title);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
          setDefaultText(fontstyle.isDefaultText);
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
          setDefaultText(fontstyle.isDefaultText);
        }
      }
      if (itemStyles.text_direction) {
        setFont((prev) => {
          return {
            ...prev,
            direction: itemStyles.text_direction,
          };
        });
      }

      setHeadingAlignment(itemStyles.heading_alignmnet);
      setHeadingTxt(itemStyles.heading_title);
      if (itemStyles.heading_title_font_style) {
        try {
          const headingStyle = JSON.parse(itemStyles.heading_title_font_style);
          setHeadingFont({
            ...headingStyle,
            color: itemStyles.heading_title_color,
            family: itemStyles.heading_title_font,
            size: itemStyles.heading_title_font_size,
          });
          setDefaultHeading(headingStyle.isDefaultHeading);
        } catch (error) {
          const headingStyle = itemStyles.heading_title_font_style;
          setHeadingFont({
            ...headingStyle,
            color: itemStyles.heading_title_color,
            family: itemStyles.heading_title_font,
            size: itemStyles.heading_title_font_size,
          });
          setDefaultHeading(headingStyle.isDefaultHeading);
          console.error("Error parsing heading title font style:", error);
        }
      }
      if (itemStyles.heading_direction) {
        setHeadingFont((prev) => {
          return {
            ...prev,
            direction: itemStyles.heading_direction,
          };
        });
      }

      setParagraphAlignment(itemStyles.paragraph_alignmnet);
      setParagraphTxt(itemStyles.paragraph_title);
      if (itemStyles.paragraph_title_font_style) {
        try {
          const parastyle = JSON.parse(itemStyles.paragraph_title_font_style);
          setParaFont({
            ...parastyle,
            color: itemStyles.paragraph_title_color,
            family: itemStyles.paragraph_title_font,
            size: itemStyles.paragraph_title_font_size,
          });
          setDefaultParagraph(parastyle.isDefaultParagraph);
        } catch (error) {
          const parastyle = itemStyles.paragraph_title_font_style;
          setParaFont({
            ...parastyle,
            color: itemStyles.paragraph_title_color,
            family: itemStyles.paragraph_title_font,
            size: itemStyles.paragraph_title_font_size,
          });
          setDefaultParagraph(parastyle.isDefaultParagraph);
        }
      }
      if (itemStyles.paragraph_direction) {
        setParaFont((prev) => {
          return {
            ...prev,
            direction: itemStyles.paragraph_direction,
          };
        });
      }

      if (itemStyles.button_font_style) {
        try {
          const buttonStyle = JSON.parse(itemStyles.button_font_style);
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
        } catch (error) {
          const buttonStyle = itemStyles.button_font_style;
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
          console.error("Error parsing heading title font style:", error);
        }
      }

      if (itemStyles.button_xy_percentage) {
        try {
          const buttonXY = JSON.parse(itemStyles.button_xy_percentage);
          setBtnPercentageXY(buttonXY);
        } catch (error) {
          const buttonXY = itemStyles.button_xy_percentage;
          setBtnPercentageXY(buttonXY);
        }
      }
      if (itemStyles.text_xy_percentage) {
        try {
          const textXY = JSON.parse(itemStyles.text_xy_percentage);
          setTextPercentageXY(textXY);
        } catch (error) {
          const textXY = itemStyles.text_xy_percentage;
          setTextPercentageXY(textXY);
        }
      }
      if (itemStyles.heading_xy_percentage) {
        try {
          const headingXY = JSON.parse(itemStyles.heading_xy_percentage);
          setHeadPercentageXY(headingXY);
        } catch (error) {
          const headingXY = itemStyles.heading_xy_percentage;
          setHeadPercentageXY(headingXY);
        }
      }
      if (itemStyles.paragraph_xy_percentage) {
        try {
          const paragraphXY = JSON.parse(itemStyles.paragraph_xy_percentage);
          setParaPercentageXY(paragraphXY);
        } catch (error) {
          const paragraphXY = itemStyles.paragraph_xy_percentage;
          setParaPercentageXY(paragraphXY);
        }
      }

      setBorder(itemStyles.border);
      setTextWidth(itemStyles.text_width);
      setTextHeight(itemStyles.text_height);
      setHeadingHeight(itemStyles.heading_height);
      setHeadingWidth(itemStyles.heading_width);
      setParaHeight(itemStyles.paragraph_height);
      setParaWidth(itemStyles.paragraph_width);

      setTextCoords({ x: itemStyles.text_x, y: itemStyles.text_y });
      setHeadingCoords({ x: itemStyles.heading_x, y: itemStyles.heading_y });
      setParagraphCoords({
        x: itemStyles.paragraph_x,
        y: itemStyles.paragraph_y,
      });
      setButtonCoords({
        x: itemStyles.button_x,
        y: itemStyles.button_y,
      });
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImgRepeat(itemStyles.image_repeat);
    }
  }, [itemStyles]);

  const [isDefault, setDefault] = useState(0);

  const [isDefaultText, setDefaultText] = useState(0);
  const [isDefaultHeading, setDefaultHeading] = useState(0);
  const [isDefaultParagraph, setDefaultParagraph] = useState(0);
  const [isDefaultButton, setDefaultButton] = useState(0);

  const dispatch = useDispatch();

  const [textCoords, setTextCoords] = useState({ x: 0, y: 0 });
  const [headingCoords, setHeadingCoords] = useState({ x: 0, y: 0 });
  const [paragraphCoords, setParagraphCoords] = useState({ x: 0, y: 0 });
  const [buttonCoords, setButtonCoords] = useState({ x: 0, y: 0 });

  const [btnPercentageXY, setBtnPercentageXY] = useState({ x: 0, y: 0 });
  const [headingPercentageXY, setHeadPercentageXY] = useState({ x: 0, y: 0 });
  const [paragraphPercentageXY, setParaPercentageXY] = useState({ x: 0, y: 0 });
  const [textPercentageXY, setTextPercentageXY] = useState({ x: 0, y: 0 });

  const [text, setText] = useState("");
  const [headingTxt, setHeadingTxt] = useState("");
  const [paraTxt, setParagraphTxt] = useState("");
  const [textAlignment, setTextAlignment] = useState("");
  const [headingAlignment, setHeadingAlignment] = useState("");
  const [paragraphAlignment, setParagraphAlignment] = useState("");
  const [buttonAlignment, setBtnAlignment] = useState("");
  const [border, setBorder] = useState("");

  const [textWidth, setTextWidth] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [headingWidth, setHeadingWidth] = useState(0);
  const [headingHeight, setHeadingHeight] = useState(0);
  const [paraWidth, setParaWidth] = useState(0);
  const [paraHeight, setParaHeight] = useState(0);
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 34,
    isDefaultFont: 1,
    direction: "left",
  });

  const [buttonFont, setButtonFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 18,
    isDefaultFont: 1,
  });

  const [headingfont, setHeadingFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 34,
    isDefaultFont: 1,
    direction: "left",
  });
  const [parafont, setParaFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 34,
    isDefaultFont: 1,
    direction: "left",
  });
  const [btnBg, setBtnBg] = useState("#ffffff");
  const [btnTxt, setBtnTxt] = useState("");
  const [txtColor, setTxtColor] = useState("#000000");
  const [btnlink, setBtnLink] = useState("");
  const [image, setImage] = useState("");
  const [imgFile, setImgFile] = useState("");

  const [link, setLink] = useState("");
  const [fileBox, showFileBox] = useState(false);

  const [textIsDashed, setTextDashed] = useState(false);
  const [headingIsDashed, setHeadDashed] = useState(false);
  const [paraIsDashed, setParaDashed] = useState(false);

  const [colorFromPicker, setColorFromPicker] = useState(false);
  useEffect(() => {
    if (
      colorFromPicker &&
      (image !== "0" || image !== null || image !== false)
    ) {
      setImage(false);
      setImgFile("");
    }
  }, [colorFromPicker]);

  const gradient = {
    points: [
      {
        left: 0,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1,
      },
      {
        left: 100,
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1,
      },
    ],
    degree: 0,
    type: "linear",
    style:
      "linear-gradient(0deg,rgba(204, 81, 81, 1) 0%,rgba(255, 0, 0, 1) 100%)",
  };

  // const [color, setColor] = useState(gradient);
  const [color, setColor] = useState(false);
  const [defaultCorners, setDefaultCorners] = useState("true");

  useEffect(() => {
    if (itemStyles === undefined) {
      setColor(universalStyles.mainColor);
    }
  }, [itemStyles]);

  useEffect(() => {
    fileBox && showFileBox(false);
    if (imgFile !== "") {
      setColor("");
    }
  }, [imgFile]);

  //COUNT for color useeffect check
  const [count, setCount] = useState(0);
  // useEffect(() => {
  //   count > 1 && setImage(false);
  //   setCount(count + 1);
  // }, [color]);

  useEffect(() => {
    if (editingMode.widgtId !== localStorage.getItem("customId")) {
      showFileBox(false);
    }
  }, [editingMode.widgtId]);

  const handleEditClick = (e) => {
    dispatch(updateTracker(true));
    if (editingMode.widgtId !== widgetId && draggable.isEditMode == false) {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
      return;
    }

    if (editingMode.widgtId !== widgetId) {
      dispatch(setEdit({ isEditMode: false, widgtId: widgetId }));
      localStorage.setItem("customId", widgetId);
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.add("hide");
      sidebaroption2.classList.add("hide");
      widgetMain.classList.add("show");
    } else {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
    }
    e.stopPropagation();
  };

  const [imagePosition, setImagePosition] = useState("100% 100%");
  const [imageRepeat, setImgRepeat] = useState("no-repeat");

  const isBase64Image = (str) => {
    const base64ImagePrefix = "data:image";
    return str.startsWith(base64ImagePrefix);
  };

  let widgetData = { widget_id: widgetId };
  useEffect(() => {
    // count > 1 && dispatch(updateTracker(true));
    // widgetData["background_image"] = image ? image : null;
    widgetData["background_image"] = imgFile ? imgFile : null;
    widgetData["background_color"] = color ? color : null;
    widgetData["defaultCorners"] = defaultCorners;
    widgetData["is_default"] = isDefault;
    widgetData["image_position"] = imagePosition;
    widgetData["image_repeat"] = imageRepeat;
    widgetData["text1"] = null;
    widgetData["heading1"] = null;
    widgetData["paragraph1"] = null;
    widgetData["button1"] = null;

    widgetData["button_background_color"] = btnBg ? btnBg : null;
    widgetData["button_text"] = btnTxt ? btnTxt : null;
    widgetData["button_text_color"] = txtColor ? txtColor : null;
    widgetData["button_link"] = btnlink ? btnlink : null;
    widgetData["button_font_style"] = {
      isBold: buttonFont.isBold,
      isUnderlined: buttonFont.isUnderlined,
      isItalic: buttonFont.isItalic,
      isDefaultFont: buttonFont.isDefaultFont,
      isDefaultButton: isDefaultButton,
    };
    widgetData["button_font_size"] = buttonFont.size ? buttonFont.size : null;
    widgetData["button_font"] = buttonFont.family ? buttonFont.family : null;

    textAlignment
      ? (widgetData["text_alignmnet"] = textAlignment)
      : (widgetData["text_alignmnet"] = null);
    widgetData["title"] = text ? text : null;
    widgetData["title_color"] = font.color ? font.color : null;
    widgetData["title_font"] = font.family ? font.family : null;
    widgetData["title_font_size"] = font.size ? font.size : null;
    widgetData["title_font_style"] = {
      isBold: font.isBold,
      isUnderlined: font.isUnderlined,
      isItalic: font.isItalic,
      isDefaultFont: font.isDefaultFont,
      isDefaultText: isDefaultText,
    };

    widgetData["date_font"] = null;
    widgetData["date_font_color"] = null;
    widgetData["date_font_size"] = null;
    widgetData["date_font_style"] = null;

    widgetData["description"] = null;
    widgetData["description_color"] = null;
    widgetData["description_font"] = null;
    widgetData["description_font_size"] = null;
    widgetData["description_font_style"] = null;

    widgetData["widget_link"] = link ? link : null;

    buttonAlignment
      ? (widgetData["button_alignmnet"] = buttonAlignment)
      : (widgetData["button_alignmnet"] = null);
    border ? (widgetData["border"] = border) : (widgetData["border"] = null);

    headingAlignment
      ? (widgetData["heading_alignmnet"] = headingAlignment)
      : (widgetData["heading_alignmnet"] = null);
    widgetData["heading_title"] = headingTxt ? headingTxt : null;
    widgetData["heading_title_color"] = headingfont.color
      ? headingfont.color
      : null;
    widgetData["heading_title_font"] = headingfont.family
      ? headingfont.family
      : null;
    widgetData["heading_title_font_size"] = headingfont.size
      ? headingfont.size
      : null;
    widgetData["heading_title_font_style"] = {
      isBold: headingfont.isBold,
      isUnderlined: headingfont.isUnderlined,
      isItalic: headingfont.isItalic,
      isDefaultFont: headingfont.isDefaultFont,
      isDefaultHeading: isDefaultHeading,
    };

    paragraphAlignment
      ? (widgetData["paragraph_alignmnet"] = paragraphAlignment)
      : (widgetData["paragraph_alignmnet"] = null);
    widgetData["paragraph_title"] = paraTxt ? paraTxt : null;
    widgetData["paragraph_title_color"] = parafont.color
      ? parafont.color
      : null;
    widgetData["paragraph_title_font"] = parafont.family
      ? parafont.family
      : null;
    widgetData["paragraph_title_font_size"] = parafont.size
      ? parafont.size
      : null;
    widgetData["paragraph_title_font_style"] = {
      isBold: parafont.isBold,
      isUnderlined: parafont.isUnderlined,
      isItalic: parafont.isItalic,
      isDefaultFont: parafont.isDefaultFont,
      isDefaultParagraph: isDefaultParagraph,
    };
    widgetData["text_height"] = textHeight ? textHeight : null;
    widgetData["text_width"] = textWidth ? textWidth : null;
    widgetData["heading_height"] = headingHeight ? headingHeight : null;
    widgetData["heading_width"] = headingWidth ? headingWidth : null;
    widgetData["paragraph_height"] = paraHeight ? paraHeight : null;
    widgetData["paragraph_width"] = paraWidth ? paraWidth : null;
    // coords
    widgetData["text_x"] = textCoords.x;
    widgetData["text_y"] = textCoords.y;
    widgetData["heading_x"] = headingCoords.x;
    widgetData["heading_y"] = headingCoords.y;
    widgetData["paragraph_x"] = paragraphCoords.x;
    widgetData["paragraph_y"] = paragraphCoords.y;
    widgetData["button_x"] = buttonCoords.x;
    widgetData["button_y"] = buttonCoords.y;
    widgetData["defaultCorners"] = defaultCorners;
    // ImagesArra
    widgetData["images"] = imagesArray;
    // col2
    widgetData["background_image2"] = null;
    widgetData["background_color2"] = null;
    widgetData["is_default2"] = null;
    widgetData["image_position2"] = null;
    widgetData["image_repeat2"] = null;
    widgetData["text2"] = null;
    widgetData["heading2"] = null;
    widgetData["paragraph2"] = null;
    widgetData["button2"] = null;
    // col3
    widgetData["background_image3"] = null;
    widgetData["background_color3"] = null;
    widgetData["is_default3"] = null;
    widgetData["image_position3"] = null;
    widgetData["image_repeat3"] = null;
    widgetData["text3"] = null;
    widgetData["heading3"] = null;
    widgetData["paragraph3"] = null;
    widgetData["button3"] = null;
    //accordion
    widgetData["accordion1"] = null;
    widgetData["accordion2"] = null;
    widgetData["accordion3"] = null;
    // Accordion group coords
    widgetData["accordionCoords1"] = null;
    widgetData["accordionCoords2"] = null;
    widgetData["accordionCoords3"] = null;

    widgetData["text_direction"] = font.direction ? font.direction : null;
    widgetData["paragraph_direction"] = parafont.direction
      ? parafont.direction
      : null;
    widgetData["heading_direction"] = headingfont.direction
      ? headingfont.direction
      : null;

    widgetData["latitude"] = null;
    widgetData["longitude"] = null;
    widgetData["place_address"] = null;

    widgetData["button_xy_percentage"] = btnPercentageXY;
    widgetData["text_xy_percentage"] = textPercentageXY;
    widgetData["heading_xy_percentage"] = headingPercentageXY;
    widgetData["paragraph_xy_percentage"] = paragraphPercentageXY;

    dispatch(updateWigetsStyles(widgetData));
  }, [
    image,
    color,
    btnBg,
    font,
    text,
    txtColor,
    btnTxt,
    link,
    btnlink,
    paraTxt,
    parafont,
    headingTxt,
    headingfont,
    textAlignment,
    paragraphAlignment,
    headingAlignment,
    buttonAlignment,
    textHeight,
    textWidth,
    headingHeight,
    headingWidth,
    paraHeight,
    paraWidth,
    textCoords,
    headingCoords,
    paragraphCoords,
    buttonCoords,
    border,
    defaultCorners,
    isDefault,
    isDefaultText,
    isDefaultHeading,
    isDefaultParagraph,
    buttonFont,
    isDefaultButton,
    imagePosition,
    imageRepeat,
    imgFile,
    imagesArray,
    btnPercentageXY,
    headingPercentageXY,
    paragraphPercentageXY,
    textPercentageXY,
  ]);

  const [isVisible, setVisible] = useState({
    text: true,
    heading: true,
    para: true,
    // button: false,
  });

  const [isShowing, setShow] = useState({
    widget: true,
    button: false,
    text: false,
  });

  //Span height
  function handleSpanChange(event, type) {
    const span = event.target;
    const width = span.offsetWidth;
    const height = span.offsetHeight;
    if (type === "text") {
      setTextWidth(width);
      setTextHeight(height);
    } else if (type === "heading") {
      setHeadingHeight(height);
      setHeadingWidth(width);
    } else if (type === "para") {
      setParaHeight(height);
      setParaWidth(width);
    }
  }
  // Hover Edit

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setTimeout(() => setIsHovered(true), 300);
  const handleMouseLeave = () => setIsHovered(false);

  const textRef = useRef(null);
  const headingRef = useRef(null);
  const paraRef = useRef(null);
  const mainRef = useRef(null);

  const textDimensions = useResizeObserver({ ref: textRef });
  const headingDimensions = useResizeObserver({ ref: headingRef });
  const paraDimension = useResizeObserver({ ref: paraRef });
  const mainDimensions = useResizeObserver({ ref: mainRef });

  useEffect(() => {
    setTextWidth(textDimensions.width);
    setTextHeight(textDimensions.height);
    setHeadingHeight(headingDimensions.height);
    setHeadingWidth(headingDimensions.width);
    setParaHeight(paraDimension.height);
    setParaWidth(paraDimension.width);
  }, [textDimensions, headingDimensions, paraDimension]);

  const imgStyles = {
    backgroundImage: `url(${image})`,
    backgroundPosition: "center",
    backgroundRepeat: imageRepeat,
    backgroundSize: imagePosition,
  };

  const commonStyles = {
    borderRadius:
      border === "round"
        ? 20
        : border === "sharp"
        ? 0
        : universalStyles.corners === "round"
        ? 20
        : 0,
  };

  const backgroundStyles = (
    image,
    default_widget_colors,
    mainColor,
    color,
    imageRepeat,
    imagePosition,
    imgFile
  ) => {
    // console.log("File>", imgFile);
    // console.log("img url", image);
    if ((image == "0" || image == null || image == false) && imgFile == "") {
      return {
        background: `${
          isDefault && default_widget_colors.custom
            ? default_widget_colors.custom.is_default === "0"
              ? default_widget_colors.custom.color
              : default_widget_colors.custom.is_default === "1"
              ? mainColor
              : getGradient(color)
            : getGradient(color)
        }`,
      };
    } else {
      return {
        backgroundImage: imgFile
          ? `url(${imgFile}`
          : `url(${process.env.REACT_APP_BASE_URL}${image}`,
        backgroundPosition: "center",
        backgroundRepeat: imageRepeat,
        backgroundSize: imagePosition,
        // backgroundColor: `${
        //   isDefault && default_widget_colors.custom
        //     ? default_widget_colors.custom.is_default === "0"
        //       ? default_widget_colors.custom.color
        //       : default_widget_colors.custom.is_default === "1"
        //       ? mainColor
        //       : getGradient(color)
        //     : getGradient(color)
        // }`,
      };
    }
  };

  const styles = {
    ...commonStyles,
    ...backgroundStyles(
      image,
      default_widget_colors,
      mainColor,
      color,
      imageRepeat,
      imagePosition,
      imgFile
    ),
  };

  return (
    <div
      id="customWidget"
      ref={mainRef}
      className={`customWidget ${`boundClass${widgetId}`} ${
        widgetId == localStorage.getItem("customId") ? "edit-mode" : ""
      }`}
      style={styles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setTimeout(() => {
          editingMode.widgtId !== widgetId && handleMouseLeave();
        }, 300);
      }}
      onClick={(e) => {
        if (editingMode.widgtId == widgetId) {
          setShow({
            widget: true,
            button: false,
            text: false,
          });
          e.stopPropagation();
        }
      }}
    >
      <div
        className={`editdeletebtn  hover-div ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => {
          dispatch(setStopDrag("yes"));
        }}
        onMouseLeave={() => {
          if (editingMode.widgtId !== widgetId) {
            dispatch(setStopDrag("no"));
          }
        }}
      >
        <div
          className="deletebtn"
          onClick={(e) => {
            if (
              editingMode.widgtId !== widgetId &&
              draggable.isEditMode == false
            ) {
              localStorage.removeItem("customId");
              localStorage.removeItem("widgetName");
              dispatch(setEdit({}));
              const sidebaroption = document.querySelector(".sidebaroption");
              const sidebaroption2 = document.querySelector(".sidebaroption2");
              const widgetMain = document.querySelector(".custom-widgetMain");
              sidebaroption.classList.remove("hide");
              sidebaroption2.classList.remove("hide");
              widgetMain.classList.remove("show");
              dispatch(setSideBar(false));
            } else {
              deleteWidget(widgetId);
            }
          }}
        >
          <img src={Assets.deletebtn} alt="Delete Image" />
        </div>
        <div className="editbtn" onClick={handleEditClick}>
          <img src={Assets.editicon} alt="Edit Image" />
        </div>
      </div>
      {/* TEXT */}
      {textAlignment && (
        <Draggable
          axis="both"
          handle=".handle"
          defaultPosition={{ x: textCoords.x, y: textCoords.y }}
          position={null}
          // grid={[25, 25]}
          scale={1}
          // disabled={isResizing}
          bounds="parent"
          onStop={(e, ui) => {
            setTextCoords({ x: ui.x, y: ui.y });
            setTextDashed(false);
            setTextPercentageXY({
              x: pixelToPercent(ui.x, "width"),
              y: pixelToPercent(ui.y, "height"),
            });
          }}
          onDrag={() => {
            setTextDashed(true);
          }}
        >
          <div
            className={`d-flex align-items-end aligned-input`}
            style={{
              pointerEvents:
                widgetId == localStorage.getItem("customId") ? "" : "none",
              ...(textIsDashed && {
                outlineStyle: "dashed",
                outlineColor: "whitesmoke",
              }),
            }}
            // onFocus={() => {
            //   setVisible({ text: false, heading: true, para: true });
            //   setShow({
            //     widget: false,
            //     button: false,
            //     text: true,
            //   });
            // }}
            onClick={(e) => {
              setVisible({ text: false, heading: true, para: true });
              setShow({
                widget: false,
                button: false,
                text: true,
              });
              e.stopPropagation();
            }}
          >
            {editingMode.widgtId == widgetId && (
              <div
                className="d-flex align-items-center justify-content-center gap-2"
                style={{
                  position: "absolute",
                  top: -44, // Align to the top of the parent div
                  left: "50%", // Center horizontally
                  transform: "translateX(-50%)",
                  backgroundColor: "rgb(255, 255, 255,0.8)",
                  borderRadius: 20,
                  padding: 6,
                  paddingInline: 10,
                }}
              >
                <div
                  className="handle "
                  style={{
                    cursor: "move",
                  }}
                  onMouseOver={() => {
                    setTextDashed(true);
                  }}
                  onMouseLeave={() => {
                    setTextDashed(false);
                  }}
                >
                  <img
                    src={Assets.dragIcon}
                    style={{
                      height: 16,
                      width: 16,
                    }}
                  />
                </div>
                <Trash2
                  onClick={() => setTextAlignment("")}
                  style={{ cursor: "pointer" }}
                  size={20}
                  color="#000"
                />
              </div>
            )}
            {textAlignment && (
              // <textarea style={{color:'#000',resize:'both'}}  />
              <span
                ref={textRef}
                id={`${widgetId.toString()}_textSpan`}
                // className="handle"
                // class="divtext"
                role="textbox"
                onChange={(e) => setText(e.target.value)}
                data-placeholder="text..."
                contentEditable
                onBlur={(e) => setText(e.target.innerText)}
                onInput={(e) => handleSpanChange(e, "text")}
                // onMouseMove={(e) => handleMouseMove(e, "textSpan")}
                suppressContentEditableWarning
                style={{
                  fontSize: Number(font.size),
                  color: isDefaultText
                    ? default_widget_colors.text.color
                    : font.color,
                  textDecorationLine: font.isUnderlined ? "underline" : "none",
                  fontWeight: font.isBold ? "bold" : "normal",
                  fontStyle: font.isItalic ? "italic" : "normal",
                  fontFamily:
                    font.family === "default"
                      ? universalStyles.font
                      : font.family
                      ? font.family
                      : "sans-serif",
                  height: itemStyles.text_height,
                  width: itemStyles.text_width,
                  resize:
                    widgetId == localStorage.getItem("customId") &&
                    !isVisible.text
                      ? "both"
                      : "none",
                  backgroundColor: "transparent",
                  maxHeight: mainDimensions.height,
                  maxWidth: mainDimensions.width,
                  overflow: "hidden",
                  pointerEvents:
                    widgetId == localStorage.getItem("customId") ? "" : "none",
                  borderColor:
                    widgetId == localStorage.getItem("customId")
                      ? "#be9494"
                      : "transparent",
                  textAlign: font.direction,
                }}
              >
                {text}
              </span>
            )}
          </div>
        </Draggable>
      )}
      {/* HEADING */}
      {headingAlignment && (
        <Draggable
          axis="both"
          handle=".handle"
          defaultPosition={{ x: headingCoords.x, y: headingCoords.y }}
          position={null}
          scale={1}
          // disabled={isResizing}
          bounds="parent"
          onStop={(e, ui) => {
            setHeadingCoords({ x: ui.x, y: ui.y });
            setHeadDashed(false);
            setHeadPercentageXY({
              x: pixelToPercent(ui.x, "width"),
              y: pixelToPercent(ui.y, "height"),
            });
          }}
          onDrag={() => {
            setHeadDashed(true);
          }}
        >
          <div
            className={`d-flex align-items-end aligned-input`}
            style={{
              pointerEvents:
                widgetId == localStorage.getItem("customId") ? "" : "none",
              ...(headingIsDashed && {
                outlineStyle: "dashed",
                outlineColor: "whitesmoke",
              }),
            }}
            // onFocus={() => {
            //   setVisible({ text: true, heading: false, para: true });
            //   setShow({
            //     widget: false,
            //     button: false,
            //     text: true,
            //   });
            // }}
            onClick={(e) => {
              setVisible({ text: true, heading: false, para: true });
              setShow({
                widget: false,
                button: false,
                text: true,
              });
              e.stopPropagation();
            }}
          >
            {editingMode.widgtId == widgetId && (
              <div
                className="handle d-flex align-items-center justify-content-center gap-2"
                style={{
                  position: "absolute",
                  top: -44, // Align to the top of the parent div
                  left: "50%", // Center horizontally
                  transform: "translateX(-50%)",
                  backgroundColor: "rgb(255, 255, 255,0.8)",
                  borderRadius: 20,
                  padding: 6,
                  paddingInline: 10,
                }}
              >
                <div
                  className="handle"
                  style={{
                    cursor: "move",
                  }}
                  onMouseOver={() => setHeadDashed(true)}
                  onMouseLeave={() => setHeadDashed(false)}
                >
                  <img
                    src={Assets.dragIcon}
                    style={{
                      height: 16,
                      width: 16,
                    }}
                  />
                </div>
                <Trash2
                  onClick={() => setHeadingAlignment("")}
                  style={{ cursor: "pointer" }}
                  size={20}
                  color="#000"
                />
              </div>
            )}
            {headingAlignment && (
              <span
                ref={headingRef}
                id={`${widgetId.toString()}_headingSpan`}
                role="textbox"
                data-placeholder="heading..."
                contentEditable
                onBlur={(e) => setHeadingTxt(e.target.innerText)}
                onInput={(e) => handleSpanChange(e, "heading")}
                suppressContentEditableWarning
                style={{
                  fontSize: Number(headingfont.size),
                  color: isDefaultHeading
                    ? default_widget_colors.heading.color
                    : headingfont.color,
                  textDecorationLine: headingfont.isUnderlined
                    ? "underline"
                    : "none",
                  fontWeight: headingfont.isBold ? "bold" : "normal",
                  fontStyle: headingfont.isItalic ? "italic" : "normal",
                  fontFamily:
                    headingfont.family === "default"
                      ? universalStyles.font
                      : headingfont.family
                      ? headingfont.family
                      : "sans-serif",
                  borderColor:
                    widgetId == localStorage.getItem("customId")
                      ? "#BE9494"
                      : "transparent",
                  resize:
                    widgetId == localStorage.getItem("customId") &&
                    !isVisible.heading
                      ? "both"
                      : "none",
                  backgroundColor: "transparent",
                  overflow: "hidden",
                  maxHeight: mainDimensions.height,
                  maxWidth: mainDimensions.width,
                  height: itemStyles.heading_height,
                  width: itemStyles.heading_width,
                  pointerEvents:
                    widgetId == localStorage.getItem("customId") ? "" : "none",
                  textAlign: headingfont.direction,
                }}
              >
                {headingTxt}
              </span>
            )}
          </div>
        </Draggable>
      )}
      {/* PARAGRAPH */}
      {paragraphAlignment && (
        <Draggable
          axis="both"
          handle=".handle"
          defaultPosition={{ x: paragraphCoords.x, y: paragraphCoords.y }}
          position={null}
          scale={1}
          // disabled={isResizing}
          bounds="parent"
          onStop={(e, ui) => {
            setParagraphCoords({ x: ui.x, y: ui.y });
            setParaDashed(false);
            setParaPercentageXY({
              x: pixelToPercent(ui.x, "width"),
              y: pixelToPercent(ui.y, "height"),
            });
          }}
          onDrag={() => {
            setParaDashed(true);
          }}
        >
          <div
            className={`d-flex align-items-end aligned-input`}
            style={{
              pointerEvents:
                widgetId == localStorage.getItem("customId") ? "" : "none",
              ...(paraIsDashed && {
                outlineStyle: "dashed",
                outlineColor: "whitesmoke",
              }),
            }}
            // onFocus={() => {
            //   setVisible({ text: true, heading: true, para: false });
            //   setShow({
            //     button: false,
            //     text: true,
            //     widget: false,
            //   });
            // }}
            onClick={(e) => {
              setVisible({ text: true, heading: true, para: false });
              setShow({
                button: false,
                text: true,
                widget: false,
              });
              e.stopPropagation();
            }}
          >
            {editingMode.widgtId == widgetId && (
              <div
                className="handle d-flex align-items-center justify-content-center gap-2"
                style={{
                  position: "absolute",
                  top: -44, // Align to the top of the parent div
                  left: "50%", // Center horizontally
                  transform: "translateX(-50%)",
                  backgroundColor: "rgb(255, 255, 255,0.8)",
                  borderRadius: 20,
                  padding: 6,
                  paddingInline: 10,
                }}
                onMouseOver={() => {
                  setParaDashed(true);
                }}
                onMouseLeave={() => {
                  setParaDashed(false);
                }}
              >
                <div
                  className="handle "
                  style={{
                    cursor: "move",
                  }}
                  onMouseOver={() => {
                    setTextDashed(true);
                  }}
                  onMouseLeave={() => {
                    setTextDashed(false);
                  }}
                >
                  <img
                    src={Assets.dragIcon}
                    style={{
                      height: 16,
                      width: 16,
                    }}
                  />
                </div>
                <Trash2
                  onClick={() => setParagraphAlignment("")}
                  style={{ cursor: "pointer" }}
                  size={20}
                  color="#000"
                />
              </div>
            )}
            {paragraphAlignment && (
              <span
                ref={paraRef}
                id={`${widgetId.toString()}_paragraphSpan`}
                // className="handle"
                // class="divtext"
                role="textbox"
                contentEditable
                onBlur={(e) => setParagraphTxt(e.target.innerText)}
                onInput={(e) => handleSpanChange(e, "para")}
                // onMouseMove={(e) => handleMouseMove(e, "paragraphSpan")}
                suppressContentEditableWarning
                data-placeholder="paragraph..."
                disabled={
                  widgetId == localStorage.getItem("customId") &&
                  !isVisible.para
                    ? false
                    : true
                }
                style={{
                  fontSize: Number(parafont.size),
                  color: isDefaultParagraph
                    ? default_widget_colors.paragraph.color
                    : parafont.color,
                  textDecorationLine: parafont.isUnderlined
                    ? "underline"
                    : "none",
                  fontWeight: parafont.isBold ? "bold" : "normal",
                  fontStyle: parafont.isItalic ? "italic" : "normal",
                  fontFamily:
                    parafont.family === "default"
                      ? universalStyles.font
                      : parafont.family
                      ? parafont.family
                      : "sans-serif",
                  resize:
                    widgetId == localStorage.getItem("customId") &&
                    !isVisible.para
                      ? "both"
                      : "none",
                  backgroundColor: "transparent",
                  overflowY: "hidden",
                  maxHeight: mainDimensions.height,
                  maxWidth: mainDimensions.width,
                  height: itemStyles.paragraph_height,
                  width: itemStyles.paragraph_width,
                  pointerEvents:
                    widgetId == localStorage.getItem("customId") ? "" : "none",
                  borderColor:
                    widgetId == localStorage.getItem("customId")
                      ? "#be9494"
                      : "transparent",
                  textAlign: parafont.direction,
                }}
              >
                {paraTxt}
              </span>
            )}
          </div>
        </Draggable>
      )}
      {/*Button*/}
      {buttonAlignment && (
        <Draggable
          axis="both"
          handle=".handle"
          defaultPosition={{ x: buttonCoords.x, y: buttonCoords.y }}
          position={null}
          scale={1}
          bounds="parent"
          onStop={(e, ui) => {
            setButtonCoords({ x: ui.x, y: ui.y });
            setBtnPercentageXY({
              x: pixelToPercent(ui.x, "width"),
              y: pixelToPercent(ui.y, "height"),
            });
          }}
        >
          <div
            // className={`aligned-button handle`}
            className={`aligned-button`}
            style={{
              justifyContent: height == 8 ? "center" : "flex-end",
              pointerEvents:
                widgetId == localStorage.getItem("customId") ? "" : "none",
            }}
            // onFocus={() => {
            //   setShow((prev) => ({
            //     ...prev,
            //     button: true,
            //     widget: false,
            //   }));
            // }}
            onClick={(e) => {
              setShow((prev) => ({
                ...prev,
                button: true,
                widget: false,
              }));
              e.stopPropagation();
            }}
          >
            {editingMode.widgtId == widgetId && (
              <div
                className="d-flex align-items-center justify-content-center gap-2"
                style={{
                  position: "absolute",
                  top: -44, // Align to the top of the parent div
                  left: "50%", // Center horizontally
                  transform: "translateX(-50%)",
                  backgroundColor: "rgb(255, 255, 255,0.8)",
                  borderRadius: 20,
                  padding: 6,
                  paddingInline: 10,
                }}
              >
                <div
                  className="handle "
                  style={{
                    cursor: "move",
                  }}
                >
                  <img
                    src={Assets.dragIcon}
                    style={{
                      height: 16,
                      width: 16,
                    }}
                  />
                </div>
                <Trash2
                  onClick={() => {
                    setBtnAlignment("");
                    setButtonCoords({ x: 0, y: 0 });
                  }}
                  style={{ cursor: "pointer" }}
                  size={20}
                  color="#000"
                />
              </div>
            )}
            <div className="submit-btn-give">
              <input
                style={{
                  // backgroundColor: btnBg,
                  background: getGradient(btnBg),
                  color: txtColor,
                  fontSize: Number(buttonFont.size),
                  fontFamily:
                    buttonFont.family === "default"
                      ? universalStyles.font
                      : buttonFont.family
                      ? buttonFont.family
                      : "sans-serif",
                  fontStyle: buttonFont.isItalic ? "italic" : "normal",
                  textDecorationLine: buttonFont.isUnderlined
                    ? "underline"
                    : "none",
                  fontWeight: buttonFont.isBold ? "bold" : "normal",
                }}
                type="submit"
                value={btnTxt ? btnTxt : "Send"}
              />
            </div>
          </div>
        </Draggable>
      )}
      {/* Images */}
      {imagesArray.length > 0 &&
        imagesArray.map((item, index) => {
          return (
            <Rnd
              className="rnd"
              dragHandleClassName="handle"
              bounds={`.boundClass${widgetId}`}
              enableResizing={
                widgetId == localStorage.getItem("customId") ? true : false
              }
              disableDragging={
                widgetId == localStorage.getItem("customId") ? false : true
              }
              // disableDragging
              lockAspectRatio
              size={{ width: item.width, height: item.height }}
              position={{ x: item.x, y: item.y }}
              onDragStop={(e, d) => {
                // console.log("STOPPED", d);
                setImagesArr((prev) => {
                  return prev.map((item, idx) => {
                    if (idx === index) {
                      return {
                        ...item,
                        x: d.x,
                        y: d.y,
                        x_percentage: pixelToPercent(d.x, "width"),
                        y_percentage: pixelToPercent(d.y, "height"),
                      };
                    }
                    return item;
                  });
                });
              }}
              onDrag={(e, d) => {
                setImagesArr((prev) => {
                  return prev.map((item, idx) => {
                    if (idx === index) {
                      return {
                        ...item,
                        x: d.x,
                        y: d.y,
                      };
                    }
                    return item;
                  });
                });
              }}
              onResize={(e, direction, ref, delta, position) => {
                setImagesArr((prev) => {
                  return prev.map((item, idx) => {
                    if (idx === index) {
                      return {
                        ...item,
                        width: ref.offsetWidth,
                        height: ref.offsetHeight,
                      };
                    }
                    return item;
                  });
                });
              }}
            >
              {editingMode.widgtId == widgetId && (
                <div
                  className="d-flex align-items-center justify-content-center gap-2"
                  style={{
                    position: "absolute",
                    top: -44, // Align to the top of the parent div
                    left: "50%", // Center horizontally
                    transform: "translateX(-50%)",
                    backgroundColor: "#fff",
                    borderRadius: 20,
                    padding: 6,
                    paddingInline: 10,
                  }}
                >
                  <div
                    className="handle "
                    style={{
                      cursor: "move",
                    }}
                  >
                    <img
                      src={Assets.dragIcon}
                      style={{
                        height: 16,
                        width: 16,
                      }}
                    />
                  </div>
                  <Trash2
                    onClick={() => {
                      setImagesArr((prev) =>
                        prev.filter((item, ix) => ix !== index)
                      );
                    }}
                    style={{ cursor: "pointer" }}
                    size={20}
                    color="#000"
                  />
                </div>
              )}
              <img
                src={
                  isBase64Image(item.image)
                    ? item.image
                    : `${process.env.REACT_APP_BASE_URL}${item.image}`
                }
                style={{
                  height: item.height,
                  width: item.width,
                  objectFit: "contain",
                  outline: editingMode.widgtId == widgetId ? "dotted" : "unset",
                }}
                alt="Logo Main"
              />
            </Rnd>
          );
        })}

      {widgetId == localStorage.getItem("customId") && (
        <WidgetTool
          isDefault={isDefault}
          setDefault={setDefault}
          color={color}
          setColor={setColor}
          showFileBox={showFileBox}
          fileBox={fileBox}
          link={link}
          setLink={setLink}
          styles={{
            width: "auto",
            // top: height == 8 ? "-6%" : height == 2 ? "-25%" : "-12%",
            top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
            right: 10,
          }}
          imagePosition={imagePosition}
          setImagePosition={setImagePosition}
          setColorFromPicker={setColorFromPicker}
          colorFromPicker={colorFromPicker}
          // imageRepeat={imageRepeat}
          // setImgRepeat={setImgRepeat}
        />
      )}
      {widgetId == localStorage.getItem("customId") && fileBox && (
        <FileDrop
          setImage={setImage}
          setImgFile={setImgFile}
          height={height}
          width={width}
          borderRadius={
            border === "round"
              ? 20
              : border === "sharp"
              ? 0
              : universalStyles.corners === "round"
              ? 20
              : 0
          }
        />
      )}

      {widgetId == localStorage.getItem("customId") &&
        (paragraphAlignment || textAlignment || headingAlignment) &&
        (!isVisible.heading || !isVisible.text || !isVisible.para) &&
        isShowing.text && (
          <TextToolbar
            hide={() => {
              setShow({
                button: false,
                text: false,
                widget: true,
              });
            }}
            setDefault={
              !isVisible.text
                ? setDefaultText
                : !isVisible.heading
                ? setDefaultHeading
                : setDefaultParagraph
            }
            isDefault={
              !isVisible.text
                ? isDefaultText
                : !isVisible.heading
                ? isDefaultHeading
                : isDefaultParagraph
            }
            font={
              !isVisible.text
                ? font
                : !isVisible.heading
                ? headingfont
                : parafont
            }
            setFont={
              !isVisible.text
                ? setFont
                : !isVisible.heading
                ? setHeadingFont
                : setParaFont
            }
            styles={{
              // top: height == 8 ? "-6%" : height == 2 ? "-25%" : "-12%",
              top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
              left: 0,
            }}
            alignment={true}
          />
        )}
      {widgetId == localStorage.getItem("customId") &&
        buttonAlignment &&
        isShowing.button && (
          <ButtonToolbar
            hide={() => {
              setShow({
                button: false,
                widget: true,
                text: false,
              });
            }}
            setDefault={setDefaultButton}
            isDefault={isDefaultButton}
            font={buttonFont}
            setFont={setButtonFont}
            btnBg={btnBg}
            btnTxt={btnTxt}
            txtColor={txtColor}
            setTxtColor={setTxtColor}
            setBtnTxt={setBtnTxt}
            setBtnBg={setBtnBg}
            btnLink={btnlink}
            setBtnLink={setBtnLink}
            styles={{
              // top: height == 8 ? "-6%" : height == 2 ? "-25%" : "-12%",
              top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
              // left: "10%",
              bottom: "unset",
              right: x == 1 ? "2%" : "unset",
              left: x == 0 ? "2%" : "unset",
            }}
          />
        )}
    </div>
  );
};

export default CustomWidget;

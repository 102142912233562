import React, { useContext, useState, useCallback, useRef } from "react";
import { Navigate, json } from "react-router-dom";
//Components
import Topbar from "../components/Topbar";
import Sidebar from "../components/sidebar/Sidebar";
//Context Api
import { DarkModeContext } from "../context/DarkModeContext";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { postRequest } from "../utills/requests";
import { useDispatch, useSelector } from "react-redux";
import {
  setEdit,
  setStyling,
  submitRequest,
  updateTracker,
} from "../store/slices/bodyStyling";
import toast from "react-hot-toast";
import {
  defaultStyles,
  serveDefaultStyles,
  teamDefaultStyles,
  watchDefaultStyles,
} from "../utills/defaultStyles";
import Loader from "../components/Loader";
import Assets from "../assets/images";
import { generateUniqueId } from "../utills/idGenerator";

const ProtectedRoute = ({ component: Component }) => {
  const { darkMode, handleLightMode, handleDarkMode, borderCorners } =
    useContext(DarkModeContext);
  const token = localStorage.getItem("accessToken");
  const pageId = useSelector((state) => state.login.pageId);
  const isLoading = useSelector((state) => state.bodyStyling.submitLoader);
  const updatedOrder = useSelector((state) => state.bodyStyling.homePageOrder);

  const dispatch = useDispatch();
  const widgets = useSelector((state) => state.bodyStyling.styling);
  if (token === "undefined" || token === null) {
    return <Navigate to="/" />;
  }

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const fileInputRef = useRef(null);
  const [activeId, setActiveId] = useState(null);

  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id);
  }, []);

  const tools = [
    "text",
    "heading",
    "paragraph",
    "button",
    "accordion",
    "image",
  ];

  const watchWidgetIds = {
    // 9: "watch_player",
    9: "watch",
    10: "chat",
    11: "notes",
    12: "sermon",
    1: "player",
    20: "group-list",
    21: "songs",
    22: "calendar",
    23: "team-details",
    24: "team-chat",
    25: "team-member",
  };

  const handleImagePick = () => {
    return new Promise((resolve) => {
      const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event.target.result);
          };
          reader.readAsDataURL(file);
        } else {
          resolve(null);
        }
        fileInputRef.current.value = "";
      };

      const handleCancel = () => {
        resolve(null);
        fileInputRef.current.value = "";
      };

      fileInputRef.current.addEventListener("change", handleFileChange, {
        once: true,
      });
      fileInputRef.current.addEventListener("cancel", handleCancel, {
        once: true,
      });
      fileInputRef.current.click();
    });
  };

  const handleDragEnd = async (e) => {
    const widgetType = localStorage.getItem("widgetName");
    if (widgetType === "column" || widgetType === "row") {
      const { id } = e.over;
      const columnNumber = id.split("").pop();
      if (e.over !== null && tools.includes(e.active.id)) {
        setActiveId(null);
        const { handleCustomStyles } = e.active.data.current;
        if (e.active.id === "text") {
          handleCustomStyles({
            text: {
              id: generateUniqueId(),
              text: "",
              fontSize: 20,
              color: "black",
              family: "default",
              height: 40,
              width: 100,
              x: 0,
              y: 45,
              isDefaultText: 0,
              isUnderlined: false,
              isBold: false,
              isItalic: false,
              direction:'left'
            },
            column: columnNumber,
          });
        } else if (e.active.id === "heading") {
          handleCustomStyles({
            heading: {
              id: generateUniqueId(),
              text: "",
              fontSize: 20,
              color: "black",
              family: "default",
              height: 40,
              width: 100,
              x: 0,
              y: 45,
              isDefaultText: 0,
              isUnderlined: false,
              isBold: false,
              isItalic: false,
              direction:'left'
            },
            column: columnNumber,
          });
        } else if (e.active.id === "paragraph") {
          handleCustomStyles({
            paragraph: {
              id: generateUniqueId(),
              text: "",
              fontSize: 20,
              color: "black",
              family: "default",
              height: 40,
              width: 100,
              x: 0,
              y: 45,
              isDefaultColor: 0,
              isUnderlined: false,
              isBold: false,
              isItalic: false,
              direction:'left'
            },
            column: columnNumber,
          });
        } else if (e.active.id === "button") {
          handleCustomStyles({
            button: {
              id: generateUniqueId(),
              text: "",
              fontSize: 20,
              txtColor: "black",
              bgColor: "white",
              family: "default",
              height: 40,
              width: 100,
              x: 0,
              y: 45,
              isDefault: 0,
              isUnderlined: false,
              isBold: false,
              isItalic: false,
              link: "",
            },
            column: columnNumber,
          });
        } else if (e.active.id === "accordion") {
          handleCustomStyles({
            accordion: {
              id: generateUniqueId(),
              x: 0,
              y: 45,
              titleBgColor: "grey",
              titleTextColor: "white",
              title: "title",
              bodyBgColor: "#9b9b9b",
              bodyTextColor: "white",
              bodyText: "detail",
              titleFontSize: 20,
              bodyFontSize: 16,
              isTitleItalic: false,
              isBodyItalic: false,
              isTitleBold: true,
              isBodyBold: true,
              isTitleUnderlined: false,
              isBodyUnderlined: false,
              titleFamily: "gilroymedium",
              defaultTitleFamily: 1,
              bodyFamily: "gilroymedium",
              defaultBodyFamily: 1,
            },
            column: columnNumber,
          });
        }
      }
    } else {
      // Restrict watch page widgets to only add once
      const widgetName = watchWidgetIds[e.active.id];
      const filteredWidget = widgets.homepageOrder.find(
        (item) => item.widgetInfo.name == widgetName
      );
      if (filteredWidget) {
        toast.error("Widget already present", { id: "already" });
        return;
      } else if (widgetName == "chat") {
        const isPlayerPresent = widgets.homepageOrder.find(
          (item) => item.widgetInfo.name == "watch_player"
        );
        if (isPlayerPresent == undefined) {
          toast.error("Watch Player must be added first", { id: "must" });
          return;
        }
      } else if (widgetName == "notes") {
        const isPlayerPresent = widgets.homepageOrder.find(
          (item) => item.widgetInfo.name == "sermon"
        );
        if (isPlayerPresent == undefined) {
          toast.error("Sermons must be added first", { id: "must" });
          return;
        }
      }

      // for custom widgets
      if (e.over !== null && tools.includes(e.active.id)) {
        setActiveId(null);
        const { handleCustomStyles } = e.active.data.current;
        if (e.active.id === "text") {
          // console.log('TEXT>>');
          handleCustomStyles({ text_alignmnet: "topLeft" });
        } else if (e.active.id === "heading") {
          handleCustomStyles({ heading_alignmnet: "topLeft" });
        } else if (e.active.id === "paragraph") {
          handleCustomStyles({ paragraph_alignmnet: "topLeft" });
        } else if (e.active.id === "button") {
          handleCustomStyles({ button_alignmnet: "topLeft" });
        } else if (e.active.id === "image") {
          const file = await handleImagePick();
          if (!file) return;
          handleCustomStyles({
            image: {
              image: file,
              height: 100,
              width: 100,
              x: 0,
              y: 45,
            },
          });
        }
      }

      if (e.over !== null && !tools.includes(e.active.id)) {
        const { openCustomSideBar } = e.active.data.current;
        setActiveId(null);
        if (
          e.active.id === "add_custom" ||
          e.active.id === "column" ||
          e.active.id === "row"
        ) {
          openCustomSideBar();
        } else {
          getWidgetId(e.active.id);
        }
      }
    }
  };

  const getWidgetId = async (widgetId) => {
    const data = {
      widget_id: widgetId,
      pageId: pageId,
    };
    try {
      dispatch(submitRequest(true));
      const res = await postRequest({
        endpoint: "/add-cloned-widget",
        payload: data,
      });
      if (res.data.success) {
        dispatch(updateTracker(true));
        cloneWidget(res.data.widget_id);
        toast.success(res.data.msg);
      }
      // console.log("RESP>", res.data);
    } catch (err) {
      console.log("ERR>>", err);
    } finally {
      dispatch(submitRequest(false));
    }
  };

  const getHighestYValue = () => {
    if (widgets.homepageOrder && widgets.homepageOrder.length === 0) {
      return 0; // or any default value if the array is empty
    }
    const highestYItem =
      widgets.homepageOrder &&
      widgets.homepageOrder.reduce((maxYItem, item) => {
        return item.y > maxYItem.y ? item : maxYItem;
      }, widgets.homepageOrder[0]);
    const highestYWithH = highestYItem.y + highestYItem.h;
    return highestYWithH;
  };

  // Update Widgets order
  const updateWidgetsOrder = (oldOrder, newOrder) => {
    const updatedArray = oldOrder.map((item1) => {
      const item2 = newOrder.find((item2) => item2.i == item1.widget_id);
      if (item2) {
        return { ...item1, x: item2.x, y: item2.y };
      } else {
        return item1;
      }
    });
    return updatedArray;
  };

  const cloneWidget = (widgetId) => {
    // console.log('CLONE>');
    const deepCopy = JSON.parse(JSON.stringify(widgets));
    const { homepageOrder } = deepCopy;
    const clonedWidget =
      pageId == 1 || pageId == 7
        ? defaultStyles[activeId]
        : pageId == 5
        ? teamDefaultStyles[activeId]
        : pageId == 6
        ? serveDefaultStyles[activeId]
        : watchDefaultStyles[activeId];
    const deepClonedWidget = JSON.parse(JSON.stringify(clonedWidget));
    // Apply modifications to the cloned widget object
    deepClonedWidget.widgetInfo = {
      ...deepClonedWidget.widgetInfo,
      id: widgetId,
    };
    if (deepClonedWidget.widget_styles) {
      deepClonedWidget.widget_styles[0] = {
        ...deepClonedWidget.widget_styles[0],
        widget_id: widgetId,
      };
    }
    const modifications = {
      y: getHighestYValue(),
      widget_id: widgetId,
    };
    Object.assign(deepClonedWidget, modifications);
    // Update order of widgets
    const newOrder = updateWidgetsOrder(homepageOrder, updatedOrder.newLayout);
    const order = [...newOrder, deepClonedWidget];
    // homepageOrder.push(deepClonedWidget);
    dispatch(
      setStyling({
        ...deepCopy,
        homepageOrder: order,
      })
    );
  };

  // const images = {
  //   2: Assets.ConnectIcon,
  //   3: Assets.GivingIcon,
  //   8: Assets.EventIcon,
  //   column: Assets.ColumnIcon,
  //   row: Assets.RowIcon,
  //   1: Assets.PlayerIcon,
  //   4: Assets.MapIcon,
  //   5: Assets.ServeIcon,
  //   6: Assets.OurCodeIcon,
  //   7: Assets.GroupIcon,
  //   add_custom: Assets.CustomIcon,
  //   9: Assets.PlayerIcon,
  // };

  return (
    <div>
      <Topbar />
      <div className="mainWrapper">
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={(e) => {
            if (e.over) {
              handleDragEnd(e);
            }
          }}
          // onDragOver={(e) => {
          //   if (e.over) {
          //     console.log("OVER>", e.over.id);
          //   }
          // }}
        >
          {/* Loader */}
          <Loader loading={isLoading} />
          {/*  */}
          <Sidebar
            handleLightMode={handleLightMode}
            handleDarkMode={handleDarkMode}
            themeMode={darkMode}
            borderCorners={borderCorners}
          />
          <Component darkMode={darkMode} />
          {activeId !== null && (
            <DragOverlay
              // adjustScale
              className="d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "grey",
                transformOrigin: "0 0",
                borderRadius: 15,
                opacity: 0.5,
                height: 80,
              }}
            >
              {/* <img
                style={{ width: "auto", height: 80 }}
                src={images[activeId]}
                alt="Widget Icon"
              /> */}
              <span style={{ color: "white", textAlign: "center" }}>
                {activeId === "add_custom" ? (
                  <>
                    {/* Add
                    <br /> */}
                    Custom Widgets
                  </>
                ) : activeId == 1 ? (
                  "Player"
                ) : activeId == 2 ? (
                  "Connect"
                ) : activeId == 3 ? (
                  "Giving"
                ) : activeId == 4 ? (
                  "Map"
                ) : activeId == 5 ? (
                  "Serve"
                ) : activeId == 6 ? (
                  "Our Code"
                ) : activeId == 7 ? (
                  "Groups"
                ) : activeId == 8 ? (
                  "Event List"
                ) : activeId == 9 ? (
                  "Watch"
                ) : activeId == 10 ? (
                  "Chat"
                ) : activeId == 11 ? (
                  "Notes"
                ) : activeId == 12 ? (
                  "Sermon"
                ) : activeId == 13 ? (
                  "Advance Giving"
                ) : activeId == 14 ? (
                  "Giving Goal"
                ) : activeId == 15 ? (
                  "Annual Budget"
                ) : activeId == 16 ? (
                  "Budget Resource"
                ) : activeId == 17 ? (
                  "Giving Statement"
                ) : activeId == 18 ? (
                  "Have Given"
                ) : activeId == 19 ? (
                  "Giving Graph"
                ) : activeId == 20 ? (
                  "Group List"
                ) : activeId == 21 ? (
                  "Songs"
                ) : activeId == 22 ? (
                  "Calendar"
                ) : activeId == 23 ? (
                  "Team Details"
                ) : activeId == 24 ? (
                  "Team Chat"
                ) : activeId == 25 ? (
                  "Team Member"
                ) : activeId == 26 ? (
                  "Team"
                ) : activeId == "widget_1" ? (
                  "Widget Size 1"
                ) : activeId == "widget_2" ? (
                  "Widget Size 2"
                ) : activeId == "widget_3" ? (
                  "Widget Size 3"
                ) : activeId == "widget_4" ? (
                  "Widget Size 4"
                ) : activeId == "widget_5" ? (
                  "Widget Size 5"
                ) : activeId == "text" ? (
                  "Text"
                ) : activeId == "heading" ? (
                  "Heading"
                ) : activeId == "paragraph" ? (
                  "Paragraph"
                ) : activeId == "button" ? (
                  "Button"
                ) : activeId == "accordion" ? (
                  "Accordion"
                ) : activeId == "column" ? (
                  "Column"
                ) : activeId == "row" ? (
                  "Row"
                ) : activeId == "image" ? (
                  "Image"
                ) : (
                  `Custom ${activeId}`
                )}
              </span>
            </DragOverlay>
          )}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/*"
          />
        </DndContext>
      </div>
    </div>
  );
};

export default ProtectedRoute;
